// 随手拍上传接口
const uploadingSnapshotUrl = `/gateway/hc-serve/miniapi/snapshot/uploadingSnapshot`;
// 随手拍详情
const snapshotDetailsUrl = `/gateway/hc-serve/miniapi/snapshot/snapshotDetails`;
// 满意度评价
const satisfactionEvaluationUrl = `/gateway/hc-serve/miniapi/snapshot/satisfactionEvaluation`;
// 随手拍列表
const snapshotListUrl = `/gateway/hc-serve/miniapi/snapshot/snapshotList`;
// 事件类型列表
const incidentTypeListUrl = `/gateway/hc-serve/manageapi/snapshot/incidentTypeList`;
// 获取当前使用房号
const userAssetListUrl = `/gateway/hc-mini/user/mini/user-asset-list`;
//获取区级联动接口
const areaLinkUrl = `/gateway/hc-space/space/getSpaceLinkList`;
//获取微信jssdk初始化
const initWxJsSdkUrl = `/gateway/hc-mini/qrcode/getPASignature`;
//获取扫描以后的地址接口
const getScanUrl = `/gateway/hc-external/reptile/qingyun`;

export {
  getScanUrl,
  initWxJsSdkUrl,
  uploadingSnapshotUrl,
  snapshotDetailsUrl,
  satisfactionEvaluationUrl,
  snapshotListUrl,
  incidentTypeListUrl,
  userAssetListUrl,
  areaLinkUrl,
};
